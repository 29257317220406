<template>
    <div class="home">

        <!-- 头部信息 -->
        <div class="topHead" v-loading="loading">
            <div class="l" v-if="bannImg && bannImg[0] && bannImg[0].children && bannImg[0].children.length !== 0">
                <swiper :options="swiperOption">
                    <swiper-slide v-for="(i, index) in bannImg[0].children" :key="index"><img
                            style="height: 368px;width: 100%;object-fit: cover;object-position: center;" :src="i.picUrl"
                            alt="" /></swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
            <div class="l" v-else>
            <img style="height: 368px;width: 100%;object-fit: cover;object-position: center;" src="@/assets/zwzp.png"
                alt="" />
            </div>
            <div class="r">
                <div class="name_content" style="align-items: baseline;">
                    <div class="tilt">店铺名称：</div>
                    <div class="name" style="width: 260px;">{{ mallStoreInfo.storeName }}</div>
                </div>
                <div class="name_content">
                    <div class="tilt">综合体验：</div>
                    <div class="rate"><el-rate v-model="mallStoreInfo.mallStoreEvaluation.serviceValue" disabled
                            :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                        </el-rate></div>
                </div>
                <div style="border-bottom: 1px dashed rgba(0, 0, 0, 0.20);margin: 20px 0;"></div>
                <div class="pj_box">
                    <div class="son">咨询评价：<span>{{ mallStoreInfo.mallStoreEvaluation.consultValue.toFixed(1) }}</span></div>
                    <div class="son">服务评价：<span>{{ mallStoreInfo.mallStoreEvaluation.serviceValue.toFixed(1) }}</span></div>
                </div>
                <div class="pj_box">
                    <div class="son">质量评价：<span>{{ mallStoreInfo.mallStoreEvaluation.qualityValue.toFixed(1) }}</span></div>
                    <div class="son">物流评价：<span>{{ mallStoreInfo.mallStoreEvaluation.logisticsValue.toFixed(1) }}</span>
                    </div>
                </div>
                <div style="border-bottom: 1px dashed rgba(0, 0, 0, 0.20);margin: 20px 0;"></div>
                <div class="tabs" v-if="mallStoreInfo.storeTags">
                    <div v-if="mallStoreInfo.storeTags.includes(',')">
                        <div class="son" v-for="(i, index) in mallStoreInfo.storeTags.split(',')" :key="index">{{ i }}</div>
                    </div>
                    <div v-else>
                        <div class="son" v-for="(i, index) in mallStoreInfo.storeTags.split('，')" :key="index">{{ i }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ancestors" v-loading="loading2">
            <div v-for="(i, index) in filteredArr" :key="index" v-show="i.children && i.children.length !== 0">
                <div class="father"
                    :style="index === filteredArr.length - 1 && filteredArr.length % 2 === 1 ? 'width:' : ''">
                    <!-- :style="index === filteredArr.length - 1 && filteredArr.length % 2 === 1 ? 'width:1200px' : ''"> -->
                    <div class="title">
                        <div class="l">
                            <div class="img">
                                <img src="@/assets/icon/tjsp.png" v-if="i.title == '商品推荐'" alt="">
                                <img src="@/assets/icon/vrzt.png" v-else-if="i.title == 'VR展店'" alt="">
                                <img src="@/assets/icon/bdyx.png" v-else-if="i.title == '榜单优选'" alt="">
                                <img src="@/assets/icon/xptj.png" v-else-if="i.title == '新品推荐'" alt="">
                                <img src="@/assets/icon/msxs.png" v-else alt="">
                            </div>
                            <div class="text">{{ i.title }}</div>
                        </div>
                        <!-- <div class="r">
                            <div class="text">更多</div>
                            <div class="img">
                                <img src="@/assets/icon/gd.png" alt="">
                            </div>
                        </div> -->
                    </div>
                    <div class="son">
                        <div class="con cur" v-for="(n, index) in i.children" :key="index" @click="commClick(n)">
                            <div class="img">
                                <img :src="n.mainImage || n.picUrl || n.panorama" alt="">
                            </div>
                            <div class="name">{{ n.title || n.name }}</div>
                            <div class="jiage" v-if="n.price">
                                <div class="l">¥{{ n.price }}</div>
                                <div class="r" style="font-size: 12px;color: #666;font-weight: 600;">
                                    <!-- <el-progress :percentage="percentage" :stroke-width="6" color="#FF4242"
                                        define-back-color="#D9D9D9" :show-text="false"></el-progress> -->
                                    销量 {{ n.sales }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- 全部商品 -->
        <commodityAll v-if="this.ids"></commodityAll>

    </div>
</template>

<script>
import {
    getStoreInfo, getshopFloor, getShopFloorInfo, getShopFloorInfoVR,
    getShopFloorInfoNewProductRecommendationListTheOptimization,
    getShopFloorInfoLimitedTimeSecondsKill
} from "@/utils/api/homeApi/index"
import commodityAll from "@/components/commodityAll.vue";
export default {
    name: 'enterpriseContent',
    components: {
        commodityAll
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 1,
                // 设置分页器
                pagination: {
                    el: ".swiper-pagination",
                    // 设置点击可切换
                    clickable: true,
                    dynamicBullets: true,
                },
                // 设置自动轮播
                autoplay: {
                    delay: 3000, // 5秒切换一次
                },
                // 设置轮播可循环
                loop: true,
            },
            swiperOption3: {
                slidesPerView: 1,
                // 设置分页器
                pagination: {
                    el: ".swiper-pagination",
                    // 设置点击可切换
                    clickable: true,
                },
                // 设置前进后退按钮
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                // 设置轮播可循环
                loop: true,
            },
            value: 4,
            percentage: 80,
            // 店铺id
            ids: '',
            // 商家店铺信息
            mallStoreInfo: {
                storeTags: '',
                mallStoreEvaluation: {
                    consultValue: 0,
                    logisticsValue: 0,
                    qualityValue: 0,
                    serviceValue: 0,
                }
            },
            // 
            loading: true,
            loading2: true,
            // 轮播图数据
            shufflingFigure: [],
            // 店铺楼层
            filteredArr: [],
            // 轮播图
            bannImg: [],
        };
    },
    mounted() {
        this.ids = this.$route.query.ids;
        localStorage.setItem('storeId', this.$route.query.ids);
        this.getStore()
        this.geFloor()

    },
    methods: {
        // 跳转
        commClick(n) {
            if (n.vrUrl) {
                window.open(n.vrUrl, '_blank');
            } else {
                window.open(`#/commodity/productDetails?ids=${n.spuId || n.bindingId}`, '_blank');
            }
        },
        // 获取店铺详细信息
        async getStore() {
            const res = await getStoreInfo(this.ids)
            // console.log(res, '店铺详细信息')
            if (res.statusCode == 8201) {
                this.$store.commit('getStore', res.data)
                this.mallStoreInfo = res.data
                this.loading = false
            }
        },
        // 获取店铺楼层
        async geFloor() {
            const res = await getshopFloor(this.ids)
            console.log(res, '店铺楼层')
            if (res.statusCode == 8201) {
                // this.filteredArr = res.data.filter(d => d.title !== "icon");
                // this.filteredArr = this.filteredArr.filter(d => d.title !== null);
                this.filteredArr = res.data
                this.filteredArr.map(async h => {
                    if (h.title == 'VR展店') {
                        const res = await getShopFloorInfoVR({ storeId: this.ids })
                        // console.log(res, "VR")
                        this.$set(h, 'children', res.data.records.slice(0, 3))
                    } else if (h.title == '新品推荐' || h.title == '榜单优选') {
                        var type = '';
                        if (h.title == '新品推荐') {
                            type = 'NEW';
                        } else {
                            type = 'SALES';
                        }
                        const res = await getShopFloorInfoNewProductRecommendationListTheOptimization({ storeId: this.ids, type: type })
                        // console.log(res, "新品推荐,榜单优选", h)
                        this.$set(h, 'children', res.data.records.slice(0, 3))
                    } else if (h.title == '限时秒杀') {
                        const res = await getShopFloorInfoLimitedTimeSecondsKill({ storeId: this.ids })
                        console.log(res, "限时秒杀", h)
                        // this.$set(h, 'children', res.data)
                    } else if (h.title == '热门类目') {
                        // this.$set(h, 'children', res.data)
                    } else {
                        const res = await getShopFloorInfo({ storeId: this.ids, homeId: h.homeId })
                        // console.log(res, "楼层数据", h)
                        this.$set(h, 'children', res.data.records.slice(0, 3))
                    }
                })
                console.log(this.filteredArr, '楼层')
                this.filteredArr = res.data.filter(d => d.title !== "icon");
                this.filteredArr = this.filteredArr.filter(d => d.title !== null);
                this.bannImg = res.data.filter(d => d.title == null && d.style == "one");
                console.log(this.bannImg, 'bannImg')  // ==> 轮播图数据
                this.loading2 = false
            }
        },
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;

    // 头部信息
    >.topHead {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;

        >.l {
            width: 800px;
            height: 368px;
            border-radius: 4px;
            overflow: hidden;
        }

        >.r {
            width: 380px;
            height: 368px;
            border-radius: 4px;
            background-color: #fff;
            padding: 25px;
            box-sizing: border-box;

            >.name_content {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;

                >.tilt {
                    font-size: 12px;
                    color: #666;
                    margin-top: 6px;
                }

                >.name {
                    font-size: 20px;
                    color: #000;
                    font-weight: bold;
                }

                >.rate {
                    margin-top: 6px;
                }
            }

            >.pj_box {
                width: 100%;
                display: flex;
                margin-bottom: 10px;

                >.son {
                    font-size: 12px;
                    color: #666;
                    margin-right: 50px;

                    >span {
                        color: #000;
                    }
                }
            }

            >.tabs {
                width: 100%;
                height: auto;
                // display: flex;
                // flex-wrap: wrap;

                >div {
                    width: 100%;
                    max-height: 150px;
                    display: flex;
                    flex-wrap: wrap;
                    overflow: hidden;

                    >.son {
                        width: auto;
                        padding: 3px 18px;
                        box-sizing: border-box;
                        background-color: #F2F2F2;
                        border-radius: 15px;
                        text-align: center;
                        font-size: 14px;
                        color: #333;
                        margin-right: 10px;
                        margin-bottom: 13px;
                    }
                }

            }
        }
    }

    // 推荐商品
    >.recommendCommodity {
        width: 1200px;
        height: 340px;
        border-radius: 8px;
        margin: 20px auto;
        padding: 15px 0;
        box-sizing: border-box;
        background-color: #fff;

        >.title {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;
            box-sizing: border-box;

            >.l {
                display: flex;
                align-items: center;

                >.img {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                >.text {
                    font-size: 24px;
                    font-weight: bold;
                    color: #333;
                }
            }

            >.r {
                display: flex;
                align-items: center;
                cursor: pointer;

                >.text {
                    font-size: 12px;
                    color: #999;
                    transition: all .3s;
                }

                >.text:hover {
                    color: #ff4242;
                    transition: all .3s;
                }

                >.img {
                    width: 16px;
                    height: 16px;
                    margin-left: 6px;
                    margin-top: 2px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        >.swiper {
            width: 100%;
            height: 280px;
            margin-top: 20px;

            .conn {
                width: 100%;
                height: auto;
                padding: 0 45px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;

                >.son {
                    width: 200px;
                    height: auto;

                    >.img {
                        width: 200px;
                        height: 200px;
                        border-radius: 6px;
                        overflow: hidden;
                        border: 1px solid rgba(0, 0, 0, .1);

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    >.name {
                        font-size: 12px;
                        color: #000;
                        white-space: nowrap;
                        /* 不自动换行 */
                        overflow: hidden;
                        /* 超出部分隐藏 */
                        text-overflow: ellipsis;
                        /* 超出部分显示省略号 */
                        margin-top: 13px;
                    }

                    >.jiag {
                        display: flex;
                        margin-top: 10px;

                        >.l {
                            font-size: 16px;
                            font-weight: bold;
                            color: #ff4242;
                            margin-right: 6px;
                        }

                        >.r {
                            font-size: 12px;
                            color: #666;
                        }
                    }
                }
            }
        }
    }

    // 新品推荐 现实秒杀
    >.recommend_seckill {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;

        >.recommend {
            width: 590px;
            height: 320px;
            border-radius: 4px;
            padding: 18px 32px;
            box-sizing: border-box;
            background-color: #fff;

            >.title {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0;
                box-sizing: border-box;

                >.l {
                    display: flex;
                    align-items: center;

                    >.img {
                        width: 32px;
                        height: 32px;
                        margin-right: 10px;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    >.text {
                        font-size: 24px;
                        font-weight: bold;
                        color: #333;
                    }
                }

                >.r {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    >.text {
                        font-size: 12px;
                        color: #999;
                        transition: all .3s;
                    }

                    >.text:hover {
                        color: #ff4242;
                        transition: all .3s;
                    }

                    >.img {
                        width: 16px;
                        height: 16px;
                        margin-left: 6px;
                        margin-top: 2px;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }

            .son {
                width: 100%;
                display: flex;

                >.con {
                    width: 165px;
                    height: 216px;
                    padding: 10px;
                    box-sizing: border-box;
                    margin-top: 30px;
                    position: relative;
                    margin-right: 15px;

                    >.img {
                        width: 145px;
                        height: 145px;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    >.name {
                        font-size: 14px;
                        color: #000;
                        margin-top: 10px;
                        white-space: nowrap;
                        /* 不自动换行 */
                        overflow: hidden;
                        /* 超出部分隐藏 */
                        text-overflow: ellipsis;
                        /* 超出部分显示省略号 */
                    }

                    >.jiage {
                        font-size: 16px;
                        font-weight: bold;
                        color: #FF4242;
                        margin-top: 10px;
                    }

                    >img {
                        position: absolute;
                        top: 0;
                    }
                }
            }

        }

        >.seckill {
            width: 590px;
            height: 320px;
            border-radius: 4px;
            padding: 18px 32px;
            box-sizing: border-box;
            background-color: #fff;

            >.title {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0;
                box-sizing: border-box;

                >.l {
                    display: flex;
                    align-items: center;

                    >.img {
                        width: 32px;
                        height: 32px;
                        margin-right: 10px;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    >.text {
                        font-size: 24px;
                        font-weight: bold;
                        color: #333;
                    }
                }

                >.r {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    >.text {
                        font-size: 12px;
                        color: #999;
                        transition: all .3s;
                    }

                    >.text:hover {
                        color: #ff4242;
                        transition: all .3s;
                    }

                    >.img {
                        width: 16px;
                        height: 16px;
                        margin-left: 6px;
                        margin-top: 2px;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }

            .son {
                width: 100%;
                display: flex;

                >.con {
                    width: 165px;
                    height: 216px;
                    padding: 10px;
                    box-sizing: border-box;
                    margin-top: 30px;
                    position: relative;
                    margin-right: 15px;

                    >.img {
                        width: 145px;
                        height: 145px;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    >.name {
                        font-size: 14px;
                        color: #000;
                        margin-top: 10px;
                        white-space: nowrap;
                        /* 不自动换行 */
                        overflow: hidden;
                        /* 超出部分隐藏 */
                        text-overflow: ellipsis;
                        /* 超出部分显示省略号 */
                    }

                    >.jiage {
                        font-size: 16px;
                        font-weight: bold;
                        color: #FF4242;
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        >.r {
                            width: 60px;
                        }
                    }

                }
            }

        }
    }

    // 榜单优选 VR展厅yi
    >.listTheOptimization_vrHall {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;

        >.listTheOptimization {
            width: 590px;
            height: 320px;
            border-radius: 4px;
            padding: 18px 32px;
            box-sizing: border-box;
            background-color: #fff;

            >.title {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0;
                box-sizing: border-box;

                >.l {
                    display: flex;
                    align-items: center;

                    >.img {
                        width: 32px;
                        height: 32px;
                        margin-right: 10px;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    >.text {
                        font-size: 24px;
                        font-weight: bold;
                        color: #333;
                    }
                }

                >.r {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    >.text {
                        font-size: 12px;
                        color: #999;
                        transition: all .3s;
                    }

                    >.text:hover {
                        color: #ff4242;
                        transition: all .3s;
                    }

                    >.img {
                        width: 16px;
                        height: 16px;
                        margin-left: 6px;
                        margin-top: 2px;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }

            >.con {
                width: 100%;
                height: auto;
                padding: 0 25px;
                box-sizing: border-box;
                margin-top: 20px;
                display: flex;

                >.img {
                    width: 220px;
                    height: 220px;
                    margin-right: 43px;
                    display: flex;
                    align-items: center;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        >.vrHall {
            width: 590px;
            height: 320px;
            border-radius: 4px;
            padding: 18px 32px;
            box-sizing: border-box;
            background-color: #fff;

            >.title {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0;
                box-sizing: border-box;

                >.l {
                    display: flex;
                    align-items: center;

                    >.img {
                        width: 32px;
                        height: 32px;
                        margin-right: 10px;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    >.text {
                        font-size: 24px;
                        font-weight: bold;
                        color: #333;
                    }
                }

                >.r {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    >.text {
                        font-size: 12px;
                        color: #999;
                        transition: all .3s;
                    }

                    >.text:hover {
                        color: #ff4242;
                        transition: all .3s;
                    }

                    >.img {
                        width: 16px;
                        height: 16px;
                        margin-left: 6px;
                        margin-top: 2px;

                        >img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                }
            }

            >.con {
                width: 100%;
                height: auto;
                padding: 0 25px;
                box-sizing: border-box;
                margin-top: 25px;

                >.top {
                    display: flex;

                    >.l {
                        >.img {
                            width: 120px;
                            height: 120px;
                            display: flex;
                            align-items: center;
                            border-radius: 4px;
                            overflow: hidden;

                            >img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    >.r {
                        margin-left: 18px;
                        margin-top: 5px;

                        >.name {
                            font-size: 18px;
                            color: #333;
                            font-weight: bold;
                        }

                        >.info {
                            font-size: 14px;
                            color: #666;
                            margin-top: 8px;

                            >span {
                                margin-left: 25px;
                            }
                        }

                        >.btn {
                            width: 116px;
                            height: 37px;
                            background-color: #FF4242;
                            border-radius: 33px;
                            font-size: 12px;
                            color: #fff;
                            text-align: center;
                            line-height: 37px;
                            margin-top: 16px;
                        }
                    }

                }

                >.bot {
                    font-size: 12px;
                    color: #666;
                    margin-top: 15px;
                }
            }
        }
    }

    //  VR展厅san
    >.vrHall_three {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        background-color: #fff;
        border-radius: 4px;
        padding: 15px 28px;
        padding-bottom: 35px;
        box-sizing: border-box;

        >.title {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            box-sizing: border-box;

            >.l {
                display: flex;
                align-items: center;

                >.img {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                >.text {
                    font-size: 24px;
                    font-weight: bold;
                    color: #333;
                }
            }

            >.r {
                display: flex;
                align-items: center;
                cursor: pointer;

                >.text {
                    font-size: 12px;
                    color: #999;
                    transition: all .3s;
                }

                >.text:hover {
                    color: #ff4242;
                    transition: all .3s;
                }

                >.img {
                    width: 16px;
                    height: 16px;
                    margin-left: 6px;
                    margin-top: 2px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        >.con {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;

            >.son {
                width: 335px;
                height: auto;
                box-sizing: border-box;
                margin-top: 15px;

                >.top {
                    display: flex;

                    >.l {
                        >.img {
                            width: 100px;
                            height: 100px;
                            display: flex;
                            align-items: center;
                            border-radius: 4px;
                            overflow: hidden;

                            >img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    >.r {
                        margin-left: 18px;
                        margin-top: 5px;

                        >.name {
                            font-size: 16px;
                            color: #333;
                            font-weight: bold;
                        }

                        >.info {
                            font-size: 12px;
                            color: #666;
                            margin-top: 4px;

                            >span {
                                margin-left: 25px;
                            }
                        }

                        >.btn {
                            width: 116px;
                            height: 37px;
                            background-color: #FF4242;
                            border-radius: 33px;
                            font-size: 12px;
                            color: #fff;
                            text-align: center;
                            line-height: 37px;
                            margin-top: 9px;
                        }
                    }

                }

                >.bot {
                    width: 100%;
                    font-size: 12px;
                    color: #666;
                    margin-top: 8px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    //  VR展厅er
    >.vrHall_two {
        width: 1200px;
        height: auto;
        margin: 20px auto;
        background-color: #fff;
        border-radius: 4px;
        padding: 15px 28px;
        padding-bottom: 40px;
        box-sizing: border-box;

        >.title {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            box-sizing: border-box;

            >.l {
                display: flex;
                align-items: center;

                >.img {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                >.text {
                    font-size: 24px;
                    font-weight: bold;
                    color: #333;
                }
            }

            >.r {
                display: flex;
                align-items: center;
                cursor: pointer;

                >.text {
                    font-size: 12px;
                    color: #999;
                    transition: all .3s;
                }

                >.text:hover {
                    color: #ff4242;
                    transition: all .3s;
                }

                >.img {
                    width: 16px;
                    height: 16px;
                    margin-left: 6px;
                    margin-top: 2px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        >.con {
            width: 100%;
            height: auto;
            display: flex;
            padding: 0 20px;
            box-sizing: border-box;
            justify-content: space-between;
            margin-top: 10px;

            >.son {
                width: 525px;
                height: auto;
                box-sizing: border-box;
                margin-top: 15px;

                >.top {
                    display: flex;

                    >.l {
                        >.img {
                            width: 120px;
                            height: 120px;
                            display: flex;
                            align-items: center;
                            border-radius: 4px;
                            overflow: hidden;

                            >img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                    }

                    >.r {
                        margin-left: 18px;
                        margin-top: 5px;

                        >.name {
                            font-size: 18px;
                            color: #333;
                            font-weight: bold;
                        }

                        >.info {
                            font-size: 14px;
                            color: #666;
                            margin-top: 4px;

                            >span {
                                margin-left: 25px;
                            }
                        }

                        >.btn {
                            width: 116px;
                            height: 37px;
                            background-color: #FF4242;
                            border-radius: 33px;
                            font-size: 12px;
                            color: #fff;
                            text-align: center;
                            line-height: 37px;
                            margin-top: 15px;
                        }
                    }

                }

                >.bot {
                    width: 100%;
                    font-size: 12px;
                    color: #666;
                    margin-top: 8px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }



}

.ancestors {
    width: 1200px;
    // min-height: 500px;
    margin: 40px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .father {
        width: 590px;
        height: 320px;
        border-radius: 4px;
        padding: 18px 20px;
        box-sizing: border-box;
        background-color: #fff;
        margin-bottom: 20px;

        >.title {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            box-sizing: border-box;

            >.l {
                display: flex;
                align-items: center;

                >.img {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                >.text {
                    font-size: 24px;
                    font-weight: bold;
                    color: #333;
                }
            }

            >.r {
                display: flex;
                align-items: center;
                cursor: pointer;

                >.text {
                    font-size: 12px;
                    color: #999;
                    transition: all .3s;
                }

                >.text:hover {
                    color: #ff4242;
                    transition: all .3s;
                }

                >.img {
                    width: 16px;
                    height: 16px;
                    margin-left: 6px;
                    margin-top: 2px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        .son {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;

            >.con {
                width: 165px;
                height: 216px;
                padding: 10px;
                box-sizing: border-box;
                margin-top: 30px;
                position: relative;
                margin-right: 15px;

                >.img {
                    width: 145px;
                    height: 145px;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                >.name {
                    font-size: 14px;
                    color: #000;
                    margin-top: 10px;
                    white-space: nowrap;
                    /* 不自动换行 */
                    overflow: hidden;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis;
                    /* 超出部分显示省略号 */
                }

                >.jiage {
                    font-size: 16px;
                    font-weight: bold;
                    color: #FF4242;
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    >.r {
                        width: 60px;
                    }
                }

            }
        }
    }
}

// 右
.swiper-button-next {
    background-color: rgba(0, 0, 0, 0.3);
    margin-right: -10px;
}

.swiper-button-next:after,
.swiper-button-next:after {
    font-size: 22px;
    color: #fff;
}

// 左
.swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.3);
    margin-left: -10px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 22px;
    color: #fff;
}

/deep/ .swiper-pagination-bullet-active {
    background-color: #ff4242 !important;
}
</style>