<template>
    <div class="commodityAll">
        <div class="l">
            <div class="top">
                <img src="@/assets/icon/fenl.png" alt="">
                <div class="text">商品分类</div>
            </div>
            <el-menu collapse-transition class="el-menu-vertical-demo" background-color="#F8F8F8" text-color="#333333"
                active-text-color="#FF4242" @select="handleSelect">
                <el-submenu :index="item.id + ''" v-for="item in storeClassificationList" :key="item.id">
                    <template slot="title">
                        <span>{{ item.typeName }}</span>
                    </template>
                    <el-menu-item :index="i.id + ''" v-for="i in item.children" :key="i.id">
                        <span
                            style="width: 150px;display: inline-block;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">{{
                                i.typeName }}</span>
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </div>
        <div class="r">
            <div class="title">
                <div class="l">
                    <div class="img">
                        <img src="@/assets/icon/qbsp.png" alt="">
                    </div>
                    <div class="text">全部商品</div>
                </div>
                <div class="r" @click="genDClick">
                    <div class="text">更多</div>
                    <div class="img">
                        <img src="@/assets/icon/gd.png" alt="">
                    </div>
                </div>
            </div>
            <div class="tuijian">
                <div class="son" @click="commodityClick(i)" v-for="(i, index) in storeCommodity" :key="index">
                    <div class="img">
                        <img :src="i.mainImage" alt="">
                    </div>
                    <div class="name">
                        {{ i.title }}
                    </div>
                    <div class="jiage">
                        <div class="l" v-if="i.price == 0">面议</div>
                        <div class="l" v-else>￥{{ i.price }}</div>
                        <div class="r">销量 {{ i.sales }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getStoreClassification, getStoreCommodity } from "@/utils/api/homeApi/index"
export default {
    data() {
        return {
            // 店铺id
            ids: '',
            // 店铺商品
            storeCommodity: [],
            // 店铺分类
            storeClassificationList: []
        }
    },
    mounted() {
        this.ids = localStorage.getItem('storeId')
        // 获取店铺分类
        this.getStoreClassificationList()
        // 获取店铺商品
        this.getStoreCommodityList()
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
            this.$router.push(`/enterpriseHome/enterpriseCommodity?tabArrIndex=1&storeCategoryId=${key}`)
        },
        // 点击跳转商品
        commodityClick(i){
            window.open(`#/commodity/productDetails?ids=${i.spuId}`, '_blank');
        },
        // 获取店铺商品分类
        async getStoreClassificationList() {
            const res = await getStoreClassification(this.ids)
            console.log(res,'获取店铺商品分类')
            if (res.statusCode == 8201) {
                this.storeClassificationList = res.data
            }
        },
        // 获取店铺商品
        async getStoreCommodityList() {
            let data = {
                title:'',
                type: 'ALL',
                storeCategoryId: '',
                storeId: this.ids,
                spuIds: '',
                currentPage: 1,
                pageSize: 12
            }
            const res = await getStoreCommodity(data)
            if (res.statusCode == 8201) {
                this.storeCommodity = res.data.records
            }
        },
        // 跳转更多商品
        genDClick(){
            this.$router.push('/enterpriseHome/enterpriseCommodity?tabArrIndex=1')
        }
    }
}
</script>

<style lang="less" scoped>
// 全部商品
.commodityAll {
    width: 1200px;
    height: auto;
    border-radius: 8px;
    background-color: #fff;
    margin: 30px auto;
    padding: 30px;
    box-sizing: border-box;
    display: flex;

    >.l {
        width: 201px;
        overflow: hidden;
        user-select: none;

        >.top {
            width: 100%;
            height: 45px;
            background-color: #FF4242;
            padding: 0 15px;
            box-sizing: border-box;
            font-size: 18px;
            color: #fff;
            display: flex;
            align-items: center;

            >img {
                margin-right: 10px;
            }
        }
    }

    >.r {
        width: 900px;
        height: auto;
        margin-left: 30px;

        >.title {
            width: 100%;
            height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            box-sizing: border-box;

            >.l {
                display: flex;
                align-items: center;

                >.img {
                    width: 32px;
                    height: 32px;
                    margin-right: 10px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                >.text {
                    font-size: 24px;
                    font-weight: bold;
                    color: #333;
                }
            }

            >.r {
                display: flex;
                align-items: center;
                cursor: pointer;

                >.text {
                    font-size: 12px;
                    color: #999;
                    transition: all .3s;
                }

                >.text:hover {
                    color: #ff4242;
                    transition: all .3s;
                }

                >.img {
                    width: 16px;
                    height: 16px;
                    margin-left: 6px;
                    margin-top: 2px;

                    >img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        >.tuijian {
            width: 100%;
            height: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 35px;

            >.son {
                width: 220px;
                height: 280px;
                background-color: #fff;
                border-radius: 4px;
                margin-bottom: 20px;
                padding: 10px;
                border: 1px solid #fff;
                box-sizing: border-box;
                transition: all .3s;
                cursor: pointer;

                >.img {
                    width: 200px;
                    height: 200px;

                    >img {
                        width: 100%;
                        height: 100%;
                    }
                }

                >.name {
                    font-size: 14px;
                    color: #000;
                    width: 100%;
                    margin-top: 10px;
                    white-space: nowrap;
                    transition: all .3s;
                    /* 禁止换行 */
                    overflow: hidden;
                    /* 超出部分隐藏 */
                    text-overflow: ellipsis;
                    /* 超出部分显示省略号 */
                }

                >.jiage {
                    width: 100%;
                    height: 25px;
                    margin-top: 5px;
                    display: flex;
                    align-items: flex-end;

                    >.l {
                        font-size: 16px;
                        font-weight: bold;
                        color: #FF4242;
                        margin-right: 6px;
                    }

                    >.r {
                        font-size: 12px;
                        color: #666;
                        margin-bottom: 2px;
                    }
                }
            }

            >.son:hover {
                border: 1px solid #FFA5A5;
                transition: all .3s;

                >.name {
                    color: #FF4242;
                    transition: all .3s;
                }
            }
        }
    }
}
</style>